import * as React from 'react';
import classNames from 'classnames';
import {
  GoogleMapProps,
  IGoogleMapsImperativeActions,
} from '../GoogleMap.types';

import { useGoogleIFrame } from './hooks';
import { SKIN_BORDERS } from './skinParts';

import style from './style/GoogleMap.scss';

import googleMapHtml from '!!url-loader?limit=1!./assets/googleMap.html';
// @ts-ignore
import googleMapsScriptUrl from '!!url-loader?limit=1&name=[name].js!./assets/google-map.min.js';

const GoogleMap: React.ForwardRefRenderFunction<
  IGoogleMapsImperativeActions,
  GoogleMapProps
> = (
  { id, skin, urlQueries, mapData, translate, onUpdateCenter, onUpdateZoom },
  forwardRef,
) => {
  const SkinBorders = SKIN_BORDERS[skin];

  const url = `${googleMapHtml}?${urlQueries}&${new URLSearchParams({
    googleMapsScriptUrl,
  })}`;

  const [render, setRender] = React.useState(false);
  const [ref] = useGoogleIFrame(forwardRef, mapData, {
    onUpdateCenter,
    onUpdateZoom,
  });

  React.useEffect(() => {
    setRender(true);
  }, [url]);

  const title = translate!(
    'component_label',
    'COMPONENT_LABEL_googleMapsTitle',
    'Google Map',
  );

  return render ? (
    <wix-iframe
      id={id}
      title={title}
      aria-label={title}
      data-src={url}
      class={classNames(style[skin], style.wixIframe)}
      tabindex={0}
    >
      <SkinBorders />
      <div id={`mapContainer_${id}`} className={style.mapContainer}>
        <iframe
          ref={ref}
          title={title}
          aria-label={title}
          data-src={url}
          width="100%"
          height="100%"
          frameBorder="0"
          scrolling="no"
          allowFullScreen
        />
      </div>
    </wix-iframe>
  ) : null;
};

export default React.forwardRef(GoogleMap);
