import {
  useMemo,
  useEffect,
  useImperativeHandle,
  ForwardRefRenderFunction,
} from 'react';
import {
  useIFrame,
  ISendMessage,
  IFrameEvent,
} from '../../../../providers/useIFrame';
import { usePrevious } from '../../../../providers/usePrevious';
import {
  GoogleMapProps,
  IGoogleMapsImperativeActions,
  GoogleMapSDKActions,
} from '../../GoogleMap.types';
import { usePromises } from './usePromise';
import { fillLocationsWithIcon, shouldKeepMarkers } from './utils';
import constants from './constants';

type GoogleMapsRef = Parameters<
  ForwardRefRenderFunction<IGoogleMapsImperativeActions, GoogleMapProps>
>[1];

type MessageHandlersByType = Record<string, (payload: any) => void>;
type EventHandlersByType = Record<
  string,
  (args: { event: IFrameEvent; _sendMessage: ISendMessage }) => void
>;

export function useGoogleIFrame(
  compRef: GoogleMapsRef,
  mapData: GoogleMapProps['mapData'],
  { onUpdateZoom, onUpdateCenter }: Partial<GoogleMapSDKActions>,
): [(node: HTMLIFrameElement) => void] {
  const [createSetCenterPromise, resolveSetCenterPromises] = usePromises();
  const [createSetZoomPromise, resolveSetZoomPromises] = usePromises();

  const modifiedMapData = useMemo(() => fillLocationsWithIcon(mapData), [
    mapData,
  ]);

  const prevMapData = usePrevious(modifiedMapData);

  const messageHandlersByType: MessageHandlersByType = {
    [constants.MESSAGE_SET_CENTER_FINISHED]: () => resolveSetCenterPromises(),
    [constants.MESSAGE_CENTER_UPDATED]: payload => onUpdateCenter?.(payload),
    [constants.MESSAGE_SET_ZOOM_FINISHED]: () => resolveSetZoomPromises(),
    [constants.MESSAGE_ZOOM_UPDATED]: payload =>
      onUpdateZoom?.({ zoom: payload }),
  };

  const eventHandlersByType: EventHandlersByType = {
    [constants.EVENT_LOAD]: ({ _sendMessage }) =>
      _sendMessage({
        type: constants.MESSAGE_SET_INITIAL_LOCATIONS,
        data: JSON.stringify(modifiedMapData),
      }),
    [constants.EVENT_MESSAGE]: ({ event }) => {
      if (typeof event.payload === 'string') {
        const { type, data } = JSON.parse(event.payload);
        messageHandlersByType[type]?.(data);
      }
    },
  };

  const reducer = (event: IFrameEvent, _sendMessage: ISendMessage) =>
    eventHandlersByType[event.type]?.({
      event,
      _sendMessage,
    });

  const [ref, sendMessage] = useIFrame(reducer);

  useImperativeHandle(compRef, () => ({
    setMapCenter: (longitude, latitude) => {
      const setCenterPromise = createSetCenterPromise();
      sendMessage({
        type: constants.MESSAGE_SET_CENTER,
        data: JSON.stringify({ longitude, latitude }),
      });
      return setCenterPromise;
    },
    setMapZoom: zoom => {
      const setZoomPromise = createSetZoomPromise();
      sendMessage({
        type: constants.MESSAGE_SET_ZOOM,
        data: zoom,
      });
      return setZoomPromise;
    },
  }));

  useEffect(() => {
    if (prevMapData) {
      sendMessage(
        JSON.stringify({
          ...modifiedMapData,
          shouldKeepMarkers: shouldKeepMarkers(
            modifiedMapData.locations,
            prevMapData.locations,
          ),
        }),
      );
    }
  }, [modifiedMapData, prevMapData, sendMessage]);

  return [ref];
}
