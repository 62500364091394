export default {
  EVENT_LOAD: 'load',
  EVENT_MESSAGE: 'message',
  MESSAGE_SET_INITIAL_LOCATIONS: 'SET_INITIAL_LOCATIONS',
  MESSAGE_SET_CENTER: 'SET_CENTER',
  MESSAGE_CENTER_UPDATED: 'CENTER_UPDATED',
  MESSAGE_SET_CENTER_FINISHED: 'SET_CENTER_FINISHED',
  MESSAGE_SET_ZOOM: 'SET_ZOOM',
  MESSAGE_ZOOM_UPDATED: 'ZOOM_UPDATED',
  MESSAGE_SET_ZOOM_FINISHED: 'SET_ZOOM_FINISHED',
};
